.exHeader {
  height: auto;
  border-bottom-width: 1px;
  border-bottom-color: @gray-2;
  border-bottom-style: solid;
  padding-right: 0px;
  padding-left: 0px;
  display: flex;
  background: @white;
  z-index: 20;
  transition: all .5s;

  @media (min-width: @screen-md) {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    will-change: transform;
  }
}

.organisationSelectorWrapper {
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-left: 10px;
  font-size: 14px;

  @media (max-width: 1000px) {
    margin-left: 0px;

    font-size: 12px;
  }

  @media (max-width: 747px) {
    display: none;
  }

  .organisationSelectorLabel {
    padding: 0px 11px;
    margin-top: 10px;
  }

  .organisationSelector {
    margin-top: -10px;
  }
}

.exHeaderScrolled {
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  box-shadow: 0 1px 24px -2px rgba(0,0,0,.1);
  background: rgba(255, 255, 255, .8);
}

.logo {
  margin-left: 132px;
  height: 56px;
  margin-top: 12px;

  @media (max-width: 1000px) {
    margin-left: 16px;
  }

  @media (min-width: 570px) {
    display: none;
  }
}

.logoLogin {
  height: 56px;
  margin-left: 132px;
  margin-top: 12px;

  @media (max-width: 768px) {
    height: 60px;
    margin-top: 34px;
    margin-right: 0px;
    margin-left: 0px;
  }

  @media (max-height: 640px) {
    margin-top: 10px;
  }
}

.logoWrapper {
  display: flex;
  height: 80px;
  flex: 1;
}

.logoWrapperLogin {
  display: flex;
  height: 80px;
  flex: 1;

  @media (max-width: 768px) {
    height: auto;
    align-items: center;
    flex-direction: column;
  }
}

.logoName {
  text-transform: uppercase;
  line-height: 24px;
  margin-bottom: 0px;
  font-weight: 800;
  letter-spacing: 0.7px;
}

.logoSubtext {
  text-transform: uppercase;
  line-height: 16px;
  margin: 0;
  font-size: 12px;
  color: @gray-8;
  font-weight: 700;
}

.logoFull {
  margin-left: 132px;
  height: 56px;
  margin-top: 12px;

  @media (max-width: 1000px) {
    margin-left: 16px;
  }

  @media (max-width: 570px) {
    display: none;
  }
}

.logoTextsLogin {
  text-decoration: none;
  margin-top: 20px;

  @media (max-width: 768px) {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 36px;
  }

  @media (max-height: 640px) {
    margin-bottom: 10px;
  }
}



@white: #FFFFFF;@golden-0: #F4E2BE;@golden-1: #C19966;@golden-2: #B58845;@gray-1: #F5F5F5;@gray-1-stronger: #DDDDDD;@gray-2: #CCCCCC;@gray-3: #BDBDBD;@gray-4: #898989;@gray-5: #6D6D6D;@gray-6: #636363;@gray-7: #525360;@gray-8: #3C3C3C;@gray-9: #E5E5E5;@black: #000000;@red: #8C2504;@red-1: #ccc0b8;@red-2: #bf9880;@red-3: #b37a5b;@red-4: #a65c3a;@red-5: #99401d;@red-6: #8c2504;@red-7: #661600;@red-8: #400c00;@purple-1: #F9F0FF;@purple-3: #D3ADF7;@purple-6: #722ED1;@geekblue-1: #F0F5FF;@geekblue-3: #ADC6FF;@geekblue-6: #2F54EB;@orange-1: #FFF7E6;@orange-3: #FFD591;@orange-6: #FA8C16;@green-1: #F6FFED;@green-3: #B7EB8F;@green-6: #52C41A;@border-radius-base: 2px;@btn-height-base: 40px;@input-height-base: 40px;@primary-color: @golden-1;@layout-header-background: #FFFFFF;@layout-body-background: #FFFFFF;@font-size-xs: 12px;@font-size-sm: 14px;@font-size-base: 16px;@font-size-lg: 20px;@heading-1-size: 30px;@heading-3-size: 20px;@heading-color: @primary-color;@label-color: @gray-6;@font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB',
  'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol';@link-decoration: none;@link-color: @gray-6;@normal-color: @gray-2;@input-border-color: @gray-2;@text-color: @gray-6;@disabled-bg: @gray-1;@disabled-color: @gray-6;@btn-disable-color: @gray-3;@btn-disable-bg: @gray-1;@btn-disable-border: @gray-2;@error-color: @red;@alert-error-bg-color: @gray-1;@btn-default-color: @golden-1;@btn-default-border: @golden-1;@success-color: @golden-1;@screen-xs: 480px;@screen-xs-min: @screen-xs;@screen-sm: 576px;@screen-sm-min: @screen-sm;@screen-md: 768px;@screen-md-min: @screen-md;@screen-lg: 992px;@screen-lg-min: @screen-lg;@screen-xl: 1200px;@screen-xl-min: @screen-xl;@screen-xxl: 1600px;@screen-xxl-min: @screen-xxl;@screen-xs-max: (@screen-sm-min - 1px);@screen-sm-max: (@screen-md-min - 1px);@screen-md-max: (@screen-lg-min - 1px);@screen-lg-max: (@screen-xl-min - 1px);@screen-xl-max: (@screen-xxl-min - 1px);@form-item-margin-bottom: 20px;@segmented-selected-bg: @primary-color;@segmented-bg: @white;@segmented-hover-bg: @gray-1;@calendar-background: #fffbf0;@calendar-border: #f0f0f0;