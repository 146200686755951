.tileContainer {
  background: #FFFFFF;
  border: 1px solid @gray-2;
  border-radius: 4px;
  width: 100%;
  min-height: 16vh;
  height: 100%;
}

.hasSubscription {
  background-color: @golden-0 !important;
  border-color: @primary-color;
  box-shadow: 0px 0px 20px @primary-color;
}

.isDeleted {
  background-color: @gray-1-stronger !important;
}

.profileWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.2rem;
}

.userProfilePicture {
  margin: 1.5rem;
}

.tag {
  margin: 0;
}

.userInfo {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
  min-width: 0;
}

.userName {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.userInfoItem {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  min-height: 22px;
  margin-top: 0.8rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 10px;
}

.userInfoWrapper {
  display: flex;
}

.horizontalSeparator {
  margin-top: 1.3rem;
  background: #FFFFFF;
  border-top: 1px solid @gray-2;
  width: 100%;
  height: 1px;
}

.verticalSeparator {
  border-left: 1px solid @gray-2;
  width: 1px;
}

.icons {
  display: flex;
  flex-direction: row;
  font-size: 1.25rem;
  margin: 0.5rem 0 0.5rem 0;
  justify-content: space-evenly;
}

.iconButton {
  display: flex;
  flex: 1;
  justify-content: center;
  transition: color 0.3s ease;
  padding: 0;
  height: 20px;
  font-size: inherit;
  border: none;

  &:hover {
    color: @golden-1;
    background-color: initial;
  }

  &:active {
    background-color: initial;
  }

  &.delete:hover {
    color: @red-6;
  }
}

.ribbon {
  position: absolute;
  right: 25px;
  top: -2px;

  filter: drop-shadow(0px 1px 1px rgba(black, 0.4));

  > .content {
    color: white;
    font-size: 1.25rem;
    text-align: center;
    font-weight: 400;
    background: @red-6;
    padding: 8px 2px 4px;

    width: 32px;
    min-height: 36px;
  }

  &.down > .content {
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), 50% 100%, 0 calc(100% - 8px));
  }
}

@white: #FFFFFF;@golden-0: #F4E2BE;@golden-1: #C19966;@golden-2: #B58845;@gray-1: #F5F5F5;@gray-1-stronger: #DDDDDD;@gray-2: #CCCCCC;@gray-3: #BDBDBD;@gray-4: #898989;@gray-5: #6D6D6D;@gray-6: #636363;@gray-7: #525360;@gray-8: #3C3C3C;@gray-9: #E5E5E5;@black: #000000;@red: #8C2504;@red-1: #ccc0b8;@red-2: #bf9880;@red-3: #b37a5b;@red-4: #a65c3a;@red-5: #99401d;@red-6: #8c2504;@red-7: #661600;@red-8: #400c00;@purple-1: #F9F0FF;@purple-3: #D3ADF7;@purple-6: #722ED1;@geekblue-1: #F0F5FF;@geekblue-3: #ADC6FF;@geekblue-6: #2F54EB;@orange-1: #FFF7E6;@orange-3: #FFD591;@orange-6: #FA8C16;@green-1: #F6FFED;@green-3: #B7EB8F;@green-6: #52C41A;@border-radius-base: 2px;@btn-height-base: 40px;@input-height-base: 40px;@primary-color: @golden-1;@layout-header-background: #FFFFFF;@layout-body-background: #FFFFFF;@font-size-xs: 12px;@font-size-sm: 14px;@font-size-base: 16px;@font-size-lg: 20px;@heading-1-size: 30px;@heading-3-size: 20px;@heading-color: @primary-color;@label-color: @gray-6;@font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB',
  'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol';@link-decoration: none;@link-color: @gray-6;@normal-color: @gray-2;@input-border-color: @gray-2;@text-color: @gray-6;@disabled-bg: @gray-1;@disabled-color: @gray-6;@btn-disable-color: @gray-3;@btn-disable-bg: @gray-1;@btn-disable-border: @gray-2;@error-color: @red;@alert-error-bg-color: @gray-1;@btn-default-color: @golden-1;@btn-default-border: @golden-1;@success-color: @golden-1;@screen-xs: 480px;@screen-xs-min: @screen-xs;@screen-sm: 576px;@screen-sm-min: @screen-sm;@screen-md: 768px;@screen-md-min: @screen-md;@screen-lg: 992px;@screen-lg-min: @screen-lg;@screen-xl: 1200px;@screen-xl-min: @screen-xl;@screen-xxl: 1600px;@screen-xxl-min: @screen-xxl;@screen-xs-max: (@screen-sm-min - 1px);@screen-sm-max: (@screen-md-min - 1px);@screen-md-max: (@screen-lg-min - 1px);@screen-lg-max: (@screen-xl-min - 1px);@screen-xl-max: (@screen-xxl-min - 1px);@form-item-margin-bottom: 20px;@segmented-selected-bg: @primary-color;@segmented-bg: @white;@segmented-hover-bg: @gray-1;@calendar-background: #fffbf0;@calendar-border: #f0f0f0;