@import '~antd/dist/antd.less';

.modules-sider .ant-table-tbody > tr > td {
  border-bottom: 0px solid #ffffff;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.section-panel-collapse .section-panel-panel > div.ant-collapse-header {
  & > span.anticon {
    height: 100%;
    display: flex;
    align-items: center;
    top: 0;
    padding-top: unset;
  }

  & > div.localizable-readonly {
    display: inline-block;
  }
}

.ant-layout-footer {
  padding: 0;
}

.ant-form-item-label > label {
  font-weight: 700;
}

.ant-checkbox + span {
  color: @gray-6;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.ant-btn-primary:not([disabled]):hover {
  background-color: @golden-2;
  border-color: @golden-2;
}

.ant-btn-default:not([disabled]):hover {
  border-color: @golden-2;
  color: @golden-2;
}

.ant-btn {
  text-transform: uppercase;
}

.ant-layout-content {
  display: flex;
}

a.link-button {
  border-bottom: @gray-6;
  border-width: 0px;
  border-bottom-width: 1px;
  border-style: solid;
  padding-bottom: 1px;

  &:hover {
    font-weight: bold;
  }
}

div.ant-result-title {
  font-size: 18px;
  color: @text-color;
}

.divider {
  margin-bottom: 32px;
  margin-top: 16px;
  height: 2px;
  background-color: @primary-color;

  @media (max-width: 768px) {
    margin-top: 18px;
    margin-bottom: 34px;
  }
}

h3.ant-typography {
  color: @gray-6 !important;
}

div.ant-tabs-tab.ant-tabs-tab-active {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;

  @media (max-width: @screen-md) {
    font-size: 18px;
  }

  @media (max-width: 350px) {
    font-size: 16px;
  }
}

div.ant-tabs-tab.ant-tabs-tab-disabled {
  color: @gray-3;
}

div.ant-tabs-tab {
  font-weight: 300;
  font-size: 20px;
  line-height: 28px;

  @media (max-width: @screen-md) {
    font-size: 18px;
  }

  @media (max-width: 350px) {
    font-size: 16px;
  }
}

div.ant-tabs-tab + .ant-tabs-tab {
  @media (max-width: @screen-md) {
    margin-left: 18px;
  }

  @media (max-width: 350px) {
    margin-left: 10px;
  }
}

.rdw-option-wrapper {
  border-color: @gray-1 !important;
  height: 30px !important;
  padding: 7px !important;

  &:hover {
    box-shadow: none !important;
    border-color: @gray-2 !important;
  }
}

.rdw-dropdown-wrapper {
  border-color: @gray-1 !important;

  &:hover {
    box-shadow: none !important;
    border-color: @gray-2 !important;
  }
}

.rdw-option-wrapper.rdw-option-active {
  border: 1.25px solid @golden-1 !important;
  box-shadow: none !important;
}

span.ant-tag {
  line-height: 23px;
}

// Errors
.ant-tag-error {
  background-color: @gray-1-stronger !important;
}

.ant-form-item-has-error .ant-form-item-control-input-content {
  input,
  textarea,
  div.ant-input,
  .ant-upload button,
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled),
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: @red;

    &:hover {
      border-color: @red;
    }
  }
}

.ant-upload-list-text {
  max-width: calc(100vw - 32px);
}

.ant-ribbon-wrapper {
  width: fit-content;
}

.ant-picker-panel-container {
  @media (max-width: 800px) {
      overflow: scroll !important;
      .ant-picker-datetime-panel {
          flex-direction: column !important;
      }
  }
}

.ant-picker-range {
  @media (max-width: 390px) {
    flex-wrap: wrap;
  }
}

.expandText {
  color: @primary-color;
}
@white: #FFFFFF;@golden-0: #F4E2BE;@golden-1: #C19966;@golden-2: #B58845;@gray-1: #F5F5F5;@gray-1-stronger: #DDDDDD;@gray-2: #CCCCCC;@gray-3: #BDBDBD;@gray-4: #898989;@gray-5: #6D6D6D;@gray-6: #636363;@gray-7: #525360;@gray-8: #3C3C3C;@gray-9: #E5E5E5;@black: #000000;@red: #8C2504;@red-1: #ccc0b8;@red-2: #bf9880;@red-3: #b37a5b;@red-4: #a65c3a;@red-5: #99401d;@red-6: #8c2504;@red-7: #661600;@red-8: #400c00;@purple-1: #F9F0FF;@purple-3: #D3ADF7;@purple-6: #722ED1;@geekblue-1: #F0F5FF;@geekblue-3: #ADC6FF;@geekblue-6: #2F54EB;@orange-1: #FFF7E6;@orange-3: #FFD591;@orange-6: #FA8C16;@green-1: #F6FFED;@green-3: #B7EB8F;@green-6: #52C41A;@border-radius-base: 2px;@btn-height-base: 40px;@input-height-base: 40px;@primary-color: @golden-1;@layout-header-background: #FFFFFF;@layout-body-background: #FFFFFF;@font-size-xs: 12px;@font-size-sm: 14px;@font-size-base: 16px;@font-size-lg: 20px;@heading-1-size: 30px;@heading-3-size: 20px;@heading-color: @primary-color;@label-color: @gray-6;@font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB',
  'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol';@link-decoration: none;@link-color: @gray-6;@normal-color: @gray-2;@input-border-color: @gray-2;@text-color: @gray-6;@disabled-bg: @gray-1;@disabled-color: @gray-6;@btn-disable-color: @gray-3;@btn-disable-bg: @gray-1;@btn-disable-border: @gray-2;@error-color: @red;@alert-error-bg-color: @gray-1;@btn-default-color: @golden-1;@btn-default-border: @golden-1;@success-color: @golden-1;@screen-xs: 480px;@screen-xs-min: @screen-xs;@screen-sm: 576px;@screen-sm-min: @screen-sm;@screen-md: 768px;@screen-md-min: @screen-md;@screen-lg: 992px;@screen-lg-min: @screen-lg;@screen-xl: 1200px;@screen-xl-min: @screen-xl;@screen-xxl: 1600px;@screen-xxl-min: @screen-xxl;@screen-xs-max: (@screen-sm-min - 1px);@screen-sm-max: (@screen-md-min - 1px);@screen-md-max: (@screen-lg-min - 1px);@screen-lg-max: (@screen-xl-min - 1px);@screen-xl-max: (@screen-xxl-min - 1px);@form-item-margin-bottom: 20px;@segmented-selected-bg: @primary-color;@segmented-bg: @white;@segmented-hover-bg: @gray-1;@calendar-background: #fffbf0;@calendar-border: #f0f0f0;