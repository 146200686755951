.icon {
    margin-right: 1.5rem;
    color: @gray-7;
    font-size: 1.75em;

    @media (max-width: 310px) {
      margin-right: 1rem;
      font-size: 1.5em;
    }

    &:hover {
        color: @primary-color;
    }
}

.filledIcon {
    color: @golden-2;
}

.wrapper {
    background-color: @white;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 16px 0px;
    max-height: calc(100vh - 80px);
    width: 500px;

    :global(.ant-spin-nested-loading) {
        width: 100%;
    }

    @media (max-width: @screen-md) {
        width: 100vw;
        height: calc(100vh - 80px);
        margin-top: 20px;
    }

    :global(.ant-tabs) {
        width: 100%;
    }

    :global(.ant-tabs-tab) {
        padding: 16px 0px;

        :global(.ant-tabs-tab-btn) {
            font-size: 16px;
            line-height: 24px;
        }
    }
}

.title {
    color: @gray-7;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px;
}

.markAsReadButton {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: @gray-7;
    text-transform: none;
    padding: 2px 8px;
}

.notificationListWrapper {
    flex-direction: row;
    max-width: 750px;

    @media (max-width: @screen-md) {
        max-width: 95vw;
    }

    :global(.ant-list-items) {
        overflow-y: auto;
        max-height: calc(100vh - 265px);
    }
    :global(.ant-typography){
        margin-bottom: unset;
    }
}

.tile {
    display: block;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    border-radius: 2px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    transition: background-color 200ms ease-out;

    @media (max-width: @screen-sm) {
        padding: 6px;
    }

    :global(.ant-badge-status-text) {
        @media (max-width: @screen-sm) {
            margin-left: 2px;
        }
    }

    &:hover {
        background-color: @gray-1;
    }

    .avatar {
        background-color: @golden-0;
        color: @golden-2;
    }

    .contentWrapper {
        display: flex;
        gap: 8px;
        margin-left: 5px;
    }

    .time {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: @gray-4;
    }
}

@white: #FFFFFF;@golden-0: #F4E2BE;@golden-1: #C19966;@golden-2: #B58845;@gray-1: #F5F5F5;@gray-1-stronger: #DDDDDD;@gray-2: #CCCCCC;@gray-3: #BDBDBD;@gray-4: #898989;@gray-5: #6D6D6D;@gray-6: #636363;@gray-7: #525360;@gray-8: #3C3C3C;@gray-9: #E5E5E5;@black: #000000;@red: #8C2504;@red-1: #ccc0b8;@red-2: #bf9880;@red-3: #b37a5b;@red-4: #a65c3a;@red-5: #99401d;@red-6: #8c2504;@red-7: #661600;@red-8: #400c00;@purple-1: #F9F0FF;@purple-3: #D3ADF7;@purple-6: #722ED1;@geekblue-1: #F0F5FF;@geekblue-3: #ADC6FF;@geekblue-6: #2F54EB;@orange-1: #FFF7E6;@orange-3: #FFD591;@orange-6: #FA8C16;@green-1: #F6FFED;@green-3: #B7EB8F;@green-6: #52C41A;@border-radius-base: 2px;@btn-height-base: 40px;@input-height-base: 40px;@primary-color: @golden-1;@layout-header-background: #FFFFFF;@layout-body-background: #FFFFFF;@font-size-xs: 12px;@font-size-sm: 14px;@font-size-base: 16px;@font-size-lg: 20px;@heading-1-size: 30px;@heading-3-size: 20px;@heading-color: @primary-color;@label-color: @gray-6;@font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB',
  'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol';@link-decoration: none;@link-color: @gray-6;@normal-color: @gray-2;@input-border-color: @gray-2;@text-color: @gray-6;@disabled-bg: @gray-1;@disabled-color: @gray-6;@btn-disable-color: @gray-3;@btn-disable-bg: @gray-1;@btn-disable-border: @gray-2;@error-color: @red;@alert-error-bg-color: @gray-1;@btn-default-color: @golden-1;@btn-default-border: @golden-1;@success-color: @golden-1;@screen-xs: 480px;@screen-xs-min: @screen-xs;@screen-sm: 576px;@screen-sm-min: @screen-sm;@screen-md: 768px;@screen-md-min: @screen-md;@screen-lg: 992px;@screen-lg-min: @screen-lg;@screen-xl: 1200px;@screen-xl-min: @screen-xl;@screen-xxl: 1600px;@screen-xxl-min: @screen-xxl;@screen-xs-max: (@screen-sm-min - 1px);@screen-sm-max: (@screen-md-min - 1px);@screen-md-max: (@screen-lg-min - 1px);@screen-lg-max: (@screen-xl-min - 1px);@screen-xl-max: (@screen-xxl-min - 1px);@form-item-margin-bottom: 20px;@segmented-selected-bg: @primary-color;@segmented-bg: @white;@segmented-hover-bg: @gray-1;@calendar-background: #fffbf0;@calendar-border: #f0f0f0;