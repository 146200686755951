.container {
  background-color: @gray-1;
  flex: 1;
  justify-content: center;
  padding-bottom: 40px;
  margin-top: 40px;

  .notFound {
    margin-top: 35px;

    :global(.ant-result-icon) {
      span {
        font-size: 200px;
      }
    }
  }
}

.calendarButton {
  margin-right: 10px;
}

.typographyText {
  margin-bottom: 0.5rem;
}

.card {
  border: 1px solid @gray-2;
  border-radius: 4px;
  padding: 40px;
  margin-top: 20px;

  @media (max-width: @screen-md) {
    padding: 25px;
  }

  @media (max-width: @screen-sm) {
    padding: 15px 20px;
  }

  @media (max-width: @screen-xs) {
    padding: 15px;
  }

  :global(.ant-divider-inner-text) {
    color: #636363;
  }
}

.mainCard {
  background-color: @white;
  padding: 40px;
  border: 1px solid @gray-2;
  border-radius: 4px;
  margin-top: 40px;

  @media (max-width: @screen-md) {
    padding: 25px;
  }

  @media (max-width: @screen-sm) {
    padding: 20px;
  }

  @media (max-width: @screen-xs) {
    padding: 20px 10px;
  }

  table {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: -16px;

    tr {
      @media (max-width: @screen-sm) {
        display: flex;
        flex-direction: column;
      }

      td {
        padding-bottom: 16px;

        :global(.ant-picker-borderless) {
          padding: 0;
        }

        &:first-child {
          @media (max-width: @screen-sm) {
            padding-bottom: 8px;
          }

          padding-right: 40px;
          font-weight: 700;
        }

        div:global(.ant-typography) {
          margin-bottom: 0;
        }
      }
    }
  }

  :global(.ant-checkbox-inner) {
    width: 24px;
    height: 24px;
    border-radius: 16px;

    &::after {
      margin-left: 2px;
    }
  }

  :global(.ant-typography-edit-content) {
    margin: 0.5rem 0 0.5rem 1rem !important;
  }

}

.clientWrapper {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 1em;
}

.titleContainer {
  display: flex;
  align-items: center;
  margin-top: 0.5em;
  flex-wrap: wrap;

  .title {
    margin-top: 0 !important;
  }
}

.fieldContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  row-gap: 35px;
  margin-bottom: 45px;
}

.createTile {
  flex: 1;
  border: 1px solid @primary-color;
  color: @primary-color;
  width: calc(50% - 10px);
  padding: 8px;
  display: flex;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    transform: scaleX(1.02) scaleY(1.05);
  }
}

.createTileDisabled {
  flex: 1;
  border: 1px solid gray;
  color: gray;
  width: calc(50% - 10px);
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
}

.title {
  color: #636363 !important;
  font-size: 24px !important;
  font-weight: 600;
  line-height: 1.35;
  margin-bottom: 0.5em !important;
}

.lawsuitSteps {
  margin: 1.5rem 1rem;
}

.lost {
  :global(.ant-steps-icon-dot) {
    background: @red-6 !important;
  }

  :global(.ant-steps-item-title) {
    color: @red-6 !important;
  }
}

.disabledCheckbox {
  :global(.ant-checkbox-inner), :global(.ant-checkbox-input) {
    cursor: default;
  }

  &:hover {
    :global(.ant-checkbox-inner) {
      border-color: #d9d9d9 !important;
    }

    :global(.ant-checkbox-checked)::after {
      border: initial !important;
    }
  }
}

.centered {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.typeContainer {
  margin-top: 9px;
}

.periodWrapper {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  gap: 0px 40px;

  .periodTagsWrapper {
    gap: 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

.activityContainer {
  width: 100%;

  .title {
    margin-top: 40px;
  }

  .sortButton {
    z-index: 1;
    border: 1px solid @gray-3;
    border-radius: 2px;
    color: @gray-6;
    text-transform: none;
    height: 28px;
    line-height: 20px;
    padding: 2px 8px;

    &:focus {
      border: 1px solid @gray-3;
      border-radius: 2px;
      color: @gray-6;
    }
  }

  .hideOnSm {
    @media (max-width: @screen-sm) {
      display: none;
    }
  }

  .showOnSm {
    display: none;

    @media (max-width: @screen-sm) {
      display: initial;
    }
  }

  .messageContent {
    margin-left: 30px;
  }
}

.messageFormWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;

  .messageForm {
    width: 100%;
    margin-left: 12px;

    @media (max-width: @screen-sm) {
      margin-left: 8px;
    }
  }

  .addCommentButton {
    margin-top: 12px;
    margin-bottom: 24px;
    text-transform: initial;
    padding: 5px 16px;
  }

  .to {
    display: flex;
    margin-bottom: 12px;

    .label {
      margin-top: 5px;
      margin-left: 12px;
      color: @gray-4;
      position: absolute;
      z-index: 1;
    }

    :global(.ant-select-selector) {
      padding-left: 2.5em;
      padding-top: 0px;
      padding-bottom: 0px;
      min-height: 32px;

      :global(.ant-select-selection-overflow) {

        :global(.ant-select-selection-item) {
          margin-top: 1px;
          margin-bottom: 1px;
        }

        :global(.ant-select-selection-item-content) {
          font-size: 14px;
        }
      }
    }
  }
}

.expertContainer {
  .expertCompany {
    margin-left: 10px;
  }
}

.stepContainer {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}

.inlineText {
  margin-bottom: unset !important;
}

.settlementTile {
  color: #636363;
  margin-top: 20px;

  * {
    transition: all .4s;
  }

  .cardContent {
    display: flex;
    flex-direction: column;

    .additionalMargin {
      margin-bottom: 8px;
    }
  }
}

.invoiceEntry {
  width: 100%;

  :global(.ant-space-item) {
    text-align: end;
  }
}

@white: #FFFFFF;@golden-0: #F4E2BE;@golden-1: #C19966;@golden-2: #B58845;@gray-1: #F5F5F5;@gray-1-stronger: #DDDDDD;@gray-2: #CCCCCC;@gray-3: #BDBDBD;@gray-4: #898989;@gray-5: #6D6D6D;@gray-6: #636363;@gray-7: #525360;@gray-8: #3C3C3C;@gray-9: #E5E5E5;@black: #000000;@red: #8C2504;@red-1: #ccc0b8;@red-2: #bf9880;@red-3: #b37a5b;@red-4: #a65c3a;@red-5: #99401d;@red-6: #8c2504;@red-7: #661600;@red-8: #400c00;@purple-1: #F9F0FF;@purple-3: #D3ADF7;@purple-6: #722ED1;@geekblue-1: #F0F5FF;@geekblue-3: #ADC6FF;@geekblue-6: #2F54EB;@orange-1: #FFF7E6;@orange-3: #FFD591;@orange-6: #FA8C16;@green-1: #F6FFED;@green-3: #B7EB8F;@green-6: #52C41A;@border-radius-base: 2px;@btn-height-base: 40px;@input-height-base: 40px;@primary-color: @golden-1;@layout-header-background: #FFFFFF;@layout-body-background: #FFFFFF;@font-size-xs: 12px;@font-size-sm: 14px;@font-size-base: 16px;@font-size-lg: 20px;@heading-1-size: 30px;@heading-3-size: 20px;@heading-color: @primary-color;@label-color: @gray-6;@font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB',
  'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol';@link-decoration: none;@link-color: @gray-6;@normal-color: @gray-2;@input-border-color: @gray-2;@text-color: @gray-6;@disabled-bg: @gray-1;@disabled-color: @gray-6;@btn-disable-color: @gray-3;@btn-disable-bg: @gray-1;@btn-disable-border: @gray-2;@error-color: @red;@alert-error-bg-color: @gray-1;@btn-default-color: @golden-1;@btn-default-border: @golden-1;@success-color: @golden-1;@screen-xs: 480px;@screen-xs-min: @screen-xs;@screen-sm: 576px;@screen-sm-min: @screen-sm;@screen-md: 768px;@screen-md-min: @screen-md;@screen-lg: 992px;@screen-lg-min: @screen-lg;@screen-xl: 1200px;@screen-xl-min: @screen-xl;@screen-xxl: 1600px;@screen-xxl-min: @screen-xxl;@screen-xs-max: (@screen-sm-min - 1px);@screen-sm-max: (@screen-md-min - 1px);@screen-md-max: (@screen-lg-min - 1px);@screen-lg-max: (@screen-xl-min - 1px);@screen-xl-max: (@screen-xxl-min - 1px);@form-item-margin-bottom: 20px;@segmented-selected-bg: @primary-color;@segmented-bg: @white;@segmented-hover-bg: @gray-1;@calendar-background: #fffbf0;@calendar-border: #f0f0f0;